import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from '../styles/bioList.module.css';
const BioList = ({ list, tagline = item => item.title }) => {
	return (
		<div className={styles.bioWrapper}>
			{list.map(item => {
				return (
					<Link to={`/bio/${item.slug}`} key={item.name} className={styles.bio}>
						{item.image ? (
							<Img className={styles.bioImg} fluid={item.image.gatsbyFile.childImageSharp.fluid} />
						) : null}
						<div className={styles.bioDetails}>
							<h4>{item.name}</h4>
							<p>{tagline(item)}</p>
						</div>
					</Link>
				);
			})}
		</div>
	);
};
export default BioList;
