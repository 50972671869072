import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BioList from '../components/bio-list';

import { graphql } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

export const query = graphql`
	query {
		indexImage: file(relativePath: { eq: "circuit-pattern.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
		cms {
			allVolunteers(sortBy: [year_DESC, role_ASC]) {
				role
				year
				volunteers {
					id
					name
					title
					slug
					image {
						publicUrl
						gatsbyFile {
							childImageSharp {
								fluid(maxWidth: 700, maxHeight: 700) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`;
const groupBy = arr =>
	arr.reduce((groups, volunteers) => {
		const key = volunteers.year;
		const group = groups.get(key) || [];
		for (let volunteerBios of volunteers.volunteers) group.push({ ...volunteerBios, role: volunteers.role });
		groups.set(key, group);
		return groups;
	}, new Map());

const AboutPageContent = ({ data }) => {
	return (
		<div>
			<BackgroundImage fluid={data.indexImage.childImageSharp.fluid}>
				<Layout>
					<SEO title='About' keywords={['about']} description='About IEEE SSCS AlexSC' />

					<h2>About us</h2>
					<p>
						IEEE SSCS AlexSC is formed to meet the needs of undergraduate and postgraduate students
						interested in the field of fabricated integrated circuit designs and all of its related topics.
						Exploiting the growing interest and successive developments in the electronics field in Egypt,
						the chapter holds a series of events and activities that aims at providing the future
						electronics engineers with the knowledge and skills they need.
					</p>
					<h2>Vision</h2>
					<p>
						To create a community of engineers who are well qualified to play an important role in the
						development of the electronics industry worldwide.
					</p>
					<h2>Our Volunteers</h2>
					<div>
						{[...groupBy(data.cms.allVolunteers).entries()].map(([key, val]) => (
							<React.Fragment key={key}>
								<h3>{key}</h3>
								<BioList
									list={[
										...val
											.reduce((groups, volunteerRole) => {
												const key = volunteerRole.id;
												let group = groups.get(key);
												if (group) group.roles.push(volunteerRole.role);
												else {
													group = { ...volunteerRole, roles: [volunteerRole.role] };
													delete group.role;
												}
												groups.set(key, group);
												return groups;
											}, new Map())
											.values()
									]}
									tagline={item => item.roles.join(' & ')}
								/>
							</React.Fragment>
						))}
					</div>
				</Layout>
			</BackgroundImage>
		</div>
	);
};

export default AboutPageContent;
